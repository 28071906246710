import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime } from 'luxon';
import { firstDayOfWeek } from '@/support/String';
import { User } from '@/models/User';
import { Setting } from '@/models/Setting';
import { AxiosError } from 'axios';
import { Organization } from '@/models/Organization';

@Component<Availability>({})
export default class Availability extends Vue {
  public $pageTitle = 'Beschikbaarheid';

  @Prop({ default: false })
  protected readOnly!: boolean;

  protected currentWeek: DateTime[] = [];

  protected availabilityStart: DateTime | null = null;

  protected availabilityEnd: DateTime | null = null;

  protected periods: DateTime[] = [];

  protected currentStartDate: DateTime = firstDayOfWeek(DateTime.local());

  protected organizations: Organization[] = [];

  protected activeOrganization = '';

  public mounted() {
    this.getSettings();
    this.getOrganizations();
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
        if (this.organizations.length) {
          this.activeOrganization = this.organizations[0].id || '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getSettings() {
    new Setting()
      .all()
      .then((settings: Setting[]) => {
        const start = settings.find((currentSetting: Setting) => currentSetting.key === 'planning_active_week');

        if (! start || ! start.value) {
          return;
        }

        this.currentStartDate = firstDayOfWeek(DateTime.fromSQL(start.value as string));
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected goToPreviousWeek() {
    this.currentStartDate = this.currentStartDate.minus({ weeks: 1 });
  }

  protected goToNextWeek() {
    this.currentStartDate = this.currentStartDate.plus({ weeks: 1 });
  }

  protected goToDate(date: DateTime) {
    this.currentStartDate = firstDayOfWeek(date);
  }

  protected getWeekNumber(date: DateTime) {
    return date.weekNumber;
  }

  protected get isPlanner() {
    return this.$store.state.Auth && (this.$store.state.Auth as User).is_planner;
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }
}
